import { Hint } from '@components/modules/hint';
import { ImageUpload } from '@components/modules/image-upload';
import { NumberInput } from '@components/modules/number-input';
import { Toggle } from '@components/modules/toggle';
import { CurrencyType } from '@enums/currency-type.enum';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { Create, Edit, SaveButton } from '@refinedev/antd';
import { usePermissions, useTranslate } from '@refinedev/core';
import {
    ButtonProps,
    Col,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Grid,
    Input,
    Row,
    Select,
    UploadFile,
} from 'antd';
import { RuleObject } from 'antd/es/form';
import { useState } from 'react';

import { PERMISSIONS } from '../../../constants/permission';
import { isHavingPermission } from '../../../utils/resource';

type ProductDetailsProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    serviceId: string;
    isEditMode: boolean;
    isSysAdmin?: boolean;
};

type ProductFormValues = {
    id?: string;
    name?: string;
    description?: string;
    frequency?: string;
    unitCost?: number;
    price?: number;

    featureImageUrl?: string | null;
    isActive?: boolean;
};
const { Option } = Select;

export const ProductCrmDetail: React.FC<ProductDetailsProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    serviceId,
    isEditMode,
}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const breakpoint = Grid.useBreakpoint();
    const { data: permissions = [] } = usePermissions<string[]>();
    const hasEditPermission = isHavingPermission(permissions, PERMISSIONS.WL_SERVICE_EDIT);
    const hasCreatePermission = isHavingPermission(permissions, PERMISSIONS.WL_SERVICE_CREATE);

    const isDisabledOnEdit = isEditMode && !hasEditPermission;
    const isDisabledOnCreate = !isEditMode && !hasCreatePermission;

    const disableFields = isDisabledOnEdit || isDisabledOnCreate;

    const initialValues: ProductFormValues =
        !isEditMode && !formProps.initialValues?.id
            ? { isActive: true, featureImageUrl: null }
            : {
                  ...formProps.initialValues,
                  //   convert price from dollar to cent to display
                  unitCost: formProps.initialValues?.unitCost * 100,
                  price: formProps.initialValues?.price * 100,
              };

    const onChangeImage = (file: UploadFile) => {
        formProps.form?.setFieldsValue({ featureImageUrl: file.response });
        setLoading(file.status === 'uploading');
    };

    const validatePrice = (_: RuleObject, value: number) => {
        const unitCostValue = formProps.form?.getFieldValue('unitCost') || 0;

        if (value < 1) {
            return Promise.reject(t('crm_internal.product.unit_cost_equal_or_greater_than_one'));
        } else if (value < unitCostValue) {
            return Promise.reject(t('crm_internal.product.price_less_than_unit_cost'));
        }

        return Promise.resolve();
    };

    const validateUnitCost = (_: RuleObject, value: number) => {
        const priceValue = +formProps.form?.getFieldValue('price');
        const unitCostValue = value;

        if (unitCostValue < 1) {
            return Promise.reject(t('crm_internal.product.unit_cost_equal_or_greater_than_one'));
        } else if (unitCostValue > priceValue) {
            return Promise.reject(t('crm_internal.product.unit_cost_greater_than_price'));
        }

        return Promise.resolve();
    };

    const onFinish = (values: Record<string, any>) => {
        if (values.unitCost <= 0 || values.price <= 0) {
            return;
        }
        values.serviceId = serviceId;
        //   convert price from cent to dollar for server
        values.price = values.price / 100;
        values.unitCost = values.unitCost / 100;

        values.frequency = ProductFrequencyEnum.ONE_TIME_PAYMENT;
        values.featureImageUrl = values.featureImageUrl?.length > 0 ? values.featureImageUrl : null;

        formProps.onFinish && formProps.onFinish(values);
    };

    const form = (
        <Form
            {...formProps}
            onFinish={onFinish}
            className="product-form-container"
            layout="vertical"
            initialValues={initialValues}
        >
            <Row gutter={20}>
                <Col md={16}>
                    <Form.Item
                        label={t('services.fields.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('crm_internal.product.name_required'),
                            },
                        ]}
                    >
                        <Input disabled={disableFields} />
                    </Form.Item>
                    <Form.Item
                        label={t('services.fields.description')}
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: t('crm_internal.product.description_required'),
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} disabled={disableFields} />
                    </Form.Item>

                    <Form.Item label={t('services.fields.frequency')} name="frequency">
                        <Select
                            defaultValue={ProductFrequencyEnum.ONE_TIME_PAYMENT}
                            disabled={disableFields}
                        >
                            <Option value={ProductFrequencyEnum.ONE_TIME_PAYMENT}>
                                {t('services.frequency.' + ProductFrequencyEnum.ONE_TIME_PAYMENT)}
                            </Option>
                        </Select>
                    </Form.Item>
                    <Row gutter={16}>
                        {!disableFields && (
                            <Col md={12}>
                                <Form.Item
                                    label={t('crm_internal.product.unit_cost')}
                                    name="unitCost"
                                    rules={[{ validator: validateUnitCost }]}
                                >
                                    <NumberInput
                                        currency={CurrencyType.Usd}
                                        isPrice={true}
                                        hasSymbols={true}
                                        defaultValue={initialValues.unitCost}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col md={12}>
                            <Form.Item
                                label={t('services.fields.price')}
                                name="price"
                                rules={[{ validator: validatePrice }]}
                            >
                                <NumberInput
                                    currency={CurrencyType.Usd}
                                    isPrice={true}
                                    hasSymbols={true}
                                    disabled={disableFields}
                                    defaultValue={initialValues.price}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label={t('services.fields.isActive')} name="isActive">
                        <Toggle disabled={disableFields} />
                    </Form.Item>
                </Col>
                <Col md={8}>
                    <Form.Item
                        label={t('services.fields.images.label')}
                        name="featureImageUrl"
                        rules={[
                            {
                                required: true,
                                message: t('crm_internal.product.image_required'),
                            },
                        ]}
                    >
                        <ImageUpload
                            width={300}
                            height={200}
                            onChange={(fileOrn) => fileOrn && onChangeImage(fileOrn)}
                            uploadText={t('crm_internal.product.image')}
                            value={initialValues?.featureImageUrl ?? undefined}
                            isReadonly={disableFields}
                            hideIconAfterUpload={disableFields}
                        />
                        <Hint width={300} height={200} size={5} fileType={['JPG', 'PNG']} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    return drawerProps.open ? (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? '700px' : '100%'}
            maskClosable={false}
            zIndex={999}
            className="drawer-form-container service-drawer"
            afterOpenChange={() => formProps.form?.resetFields()}
            title={
                !disableFields ? (
                    <span className="text-lg">
                        {!isEditMode
                            ? t('crm_internal.product.create_product')
                            : t('crm_internal.product.edit_product')}
                    </span>
                ) : (
                    <span className="text-lg">{`${t('crm_internal.product.detail')} - ${
                        formProps.initialValues?.name
                    }`}</span>
                )
            }
            extra={!disableFields ? <SaveButton {...saveButtonProps} /> : null}
        >
            {!isEditMode ? (
                <Create
                    title={<></>}
                    breadcrumb={null}
                    headerProps={{ backIcon: null }}
                    isLoading={!!loading}
                    footerButtons={<></>}
                >
                    {form}
                </Create>
            ) : (
                <Edit
                    title={<></>}
                    breadcrumb={null}
                    headerProps={{ backIcon: null, extra: null }}
                    isLoading={loading || !initialValues?.id}
                    footerButtons={<></>}
                >
                    {form}
                </Edit>
            )}
        </Drawer>
    ) : null;
};
