import './styles.scss';

import { CurrencyType } from '@enums/currency-type.enum';
import { validatePrice } from '@utils/validator';
import { Input } from 'antd';
import { centToDollar, dollarToCent, modifyFloatPrice } from 'common/functions/format-price';
import { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';

export const NumberInput: React.FC<{
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: string | number;
    isPrice?: boolean;
    hasSymbols?: boolean;
    isInteger?: boolean;
    currency?: CurrencyType | null;
    onChange?: (value: string | number) => void;
    className?: string;
    onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}> = ({
    hasSymbols = true,
    placeholder = '',
    disabled = false,
    isPrice = false,
    defaultValue = '',
    isInteger = false,
    currency,
    onChange,
    className,
    onBlur,
    onPressEnter,
}) => {
    const [value, setValue] = useState<string | number>('');

    useEffect(() => {
        setValue(
            isPrice && defaultValue
                ? centToDollar(defaultValue)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : defaultValue ?? '',
        );
    }, [defaultValue]);

    const validateNumber = (_value: string): string | undefined => {
        if (!_value || validatePrice(_value, currency)) {
            const price = modifyFloatPrice(_value);
            return price ? price.toString() : '';
        }
    };

    const handleChange = (_value: string) => {
        if (isPrice) {
            const realValue = _value.replaceAll(',', '');
            const handleValue = validateNumber(realValue);
            if (handleValue) {
                const formatValue = handleValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                setValue(formatValue);
                const emitValue = dollarToCent(handleValue);
                onChange && onChange(emitValue);
            } else if (handleValue === '') {
                setValue(handleValue);
                onChange && onChange('');
            }
        } else {
            if (!_value || isInteger ? /^[0-9]*$/.test(_value) : /^\d+(\.\d{0,2})?$/.test(_value)) {
                const handleValue = modifyFloatPrice(_value);
                setValue(handleValue);
                onChange && onChange(handleValue);
            }
        }
    };

    return (
        <Input
            addonBefore={hasSymbols && isPrice && currency === CurrencyType.Usd ? '$' : 'đ'}
            addonAfter={hasSymbols && !isPrice && '%'}
            placeholder={placeholder}
            onChange={(event) => handleChange(event?.target?.value ?? '')}
            value={value}
            disabled={disabled}
            className={`input ${className}`}
            onBlur={onBlur}
            onPressEnter={onPressEnter}
        />
    );
};
