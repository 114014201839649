import { convertPrice, formatDate } from '@utils/resource';
import { ContentTable } from 'pdfmake/interfaces';
import { IPartner } from '../../../../interfaces/partner';
import { DATE_FORMAT_PERIOD } from '../../../../constants/index.constant';

export const renderTransactionSummary = (
    data: IPartner.IPartnerTransaction[],
    partnerName: string,
    fn: (rs: string, obj: any) => string,
) => {
    const columnGap = 6;

    return [
        {
            columns: [
                {
                    width: '100%',
                    stack: [
                        {
                            columnGap: columnGap,
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${fn(`partners.payout_table.pdf.reseller_name`, {
                                        ns: 'partner',
                                    })}: `,
                                    style: 'label',
                                },
                                {
                                    width: '*',
                                    text: `${partnerName}`,
                                    style: 'value',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            columns: [
                {
                    width: '100%',
                    stack: [
                        {
                            columnGap: columnGap,
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${fn(`partners.transaction.total_transaction`, {
                                        ns: 'partner',
                                    })}: `,
                                    style: 'label',
                                },
                                {
                                    width: '*',
                                    text: `${data.length || 0}`,
                                    style: 'value',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};

export const renderTransactionTable = (
    data: IPartner.IPartnerTransaction[],
    fn: (rs: string, obj: any) => string,
) => {
    const tableData: ContentTable = {
        style: 'tableExample',
        table: {
            headerRows: 1,
            widths: ['*', 'auto', '*', '*', 'auto'],
            body: [
                [
                    {
                        text: fn('partners.transaction.date', { ns: 'partner' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('partners.transaction.invoice', { ns: 'partner' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('partners.transaction.transaction_amount', { ns: 'partner' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('partners.transaction.commission_amount', { ns: 'partner' }),
                        style: 'tableHeader',
                    },
                    {
                        text: fn('partners.transaction.contact', { ns: 'partner' }),
                        style: 'tableHeader',
                    },
                ],
            ],
        },
    };

    data.forEach((item: IPartner.IPartnerTransaction) => {
        tableData?.table.body.push([
            {
                text: formatDate(item.createdAt.toString(), DATE_FORMAT_PERIOD),
                style: 'normalText',
            },
            {
                text: `# ${item.invoiceIncrementId}`,
                style: 'normalText',
            },
            {
                text: convertPrice(+item.amount),
                style: 'normalText',
            },
            {
                text: convertPrice(+item.commission),
                style: 'normalText',
            },
            {
                text: item.contactName,
                style: 'normalText',
            },
        ]);
    });
    return tableData;
};
