import './styles.scss';

import { CustomSearch } from '@components/modules/custom-search';
import { useTable } from '@refinedev/core';
import { Table, Tabs } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Content } from 'pdfmake/interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ExportTypeEnum } from '../../../../enums/export-file.enum';
import { IPartner } from '../../../../interfaces/partner';
import { NPDFMakeSetting } from '../../../../interfaces/pdf-make.setting';
import { formatDate } from '../../../../utils/date';
import { convertPrice } from '../../../../utils/resource';
import { CustomPagination } from '../../../modules/pagination';
import { DownloadPrint } from '../download-pdf';
import { renderTransactionSummary, renderTransactionTable } from './print-pdf-transaction';
dayjs.extend(timezone);
enum TabsEnum {
    TRANSACTION = 'transaction',
}
export const PAGE_SIZE = 5;
interface PartnerCrmTransactionProps {
    partnerId: string;
    partnerName: string;
}
export const PartnerCrmTransaction = ({ partnerId, partnerName }: PartnerCrmTransactionProps) => {
    const { t } = useTranslation(['common', 'partner', 'quote']);
    const timeZone = dayjs.tz.guess();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const [tab, setTab] = useState<TabsEnum>(TabsEnum?.TRANSACTION);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [dataToPrint, setDataToPrint] = useState<NPDFMakeSetting.IReportBody>();

    const [filter, setFilter] = useState<string>('');

    const onChangeTab = (e: TabsEnum) => {
        setTab(e);
    };

    const {
        tableQueryResult: partnerTransactionData,
        setPageSize,
        setCurrent,
        current,
        pageSize,
    } = useTable<IPartner.IPartnerTransaction>({
        resource: 'v1/invoices/transaction',
        dataProviderName,
        queryOptions: {
            retry: 1,
        },
        initialPageSize: 5,
        permanentFilter: [
            {
                field: 'resellerId',
                operator: 'eq',
                value: partnerId,
            },
            {
                field: 'filter',
                operator: 'eq',
                value: filter,
            },
        ],
    });

    const partnerTransactions = partnerTransactionData.data?.data || [];

    useEffect(() => {
        setCurrent(1);
    }, [filter]);

    useEffect(() => {
        let summaryData: Content[] = [];
        const tableData: Content[] = [];

        if (partnerTransactions) {
            summaryData = renderTransactionSummary(
                partnerTransactions,
                partnerName,
                (rs: string, obj: any) => t(rs, obj),
            );
        }
        if (partnerTransactions?.length) {
            const title = '';
            const resultMapTable = renderTransactionTable(
                partnerTransactions,
                (rs: string, obj: any) => t(rs, obj),
            );
            tableData.push(title, resultMapTable);
        }

        const transactionPdfData: NPDFMakeSetting.IReportBody = {
            summarySection: summaryData || [],
            tableBody: tableData || [],
        };

        setDataToPrint(transactionPdfData);
    }, [partnerTransactions, partnerId]);

    const tabBarExtraContent = (
        <div className="flex">
            <CustomSearch
                placeholder={t('quotes.fields.search.invoce_placeholder', { ns: 'quote' })}
                className={'search-item p-3'}
                onChange={(event: any) => {
                    setFilter(event?.target.value);
                    setPageIndex(1);
                }}
                allowClear={true}
            />
            <DownloadPrint
                title={t('partners.transaction.transaction_of', {
                    ns: 'partner',
                    resellerName: partnerName,
                })}
                resellerId={partnerId}
                resellerName={partnerName}
                dataToPrint={dataToPrint}
                filter={filter}
                reportType={ExportTypeEnum.RESELLER_TRANSACTION}
                timeZone={timeZone}
            />
        </div>
    );

    return (
        <div className="contact-payments-invoices overflow-hidden">
            <Tabs
                tabBarExtraContent={tabBarExtraContent}
                onChange={(e) => onChangeTab(e as TabsEnum)}
                defaultActiveKey={TabsEnum.TRANSACTION}
            >
                <Tabs.TabPane
                    tab={t('partners.transaction.label', { ns: 'partner' })}
                    key={TabsEnum.TRANSACTION}
                >
                    <div className="overflow-hidden">
                        <div className="list-content table-wrapper">
                            <Table
                                dataSource={partnerTransactions}
                                pagination={false}
                                tableLayout="fixed"
                                scroll={{ x: '900px' }}
                                rowKey="id"
                                loading={partnerTransactionData.isLoading}
                            >
                                <Table.Column
                                    title={<>{t('partners.transaction.date', { ns: 'partner' })}</>}
                                    dataIndex="createdAt"
                                    key="createdAt"
                                    width={200}
                                    render={(createdAt) => {
                                        return (
                                            <p className="table-tbody-text">
                                                {formatDate(createdAt)}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>{t('partners.transaction.invoice', { ns: 'partner' })}</>
                                    }
                                    dataIndex="invoiceIncrementId"
                                    key="invoiceIncrementId"
                                    width={100}
                                    render={(
                                        invoiceIncrementId,
                                        record: IPartner.IPartnerTransaction,
                                    ) => {
                                        return (
                                            <Link
                                                to={`/sale/invoices/show/${record.invoiceId}`}
                                                className="table-tbody-text"
                                            >
                                                <u> # {invoiceIncrementId}</u>
                                            </Link>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>
                                            {t('partners.transaction.transaction_amount', {
                                                ns: 'partner',
                                            })}
                                        </>
                                    }
                                    dataIndex="amount"
                                    key="amount"
                                    width={100}
                                    render={(amount) => {
                                        return (
                                            <p className="table-tbody-text">
                                                {convertPrice(amount)}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>
                                            {t('partners.transaction.commission_amount', {
                                                ns: 'partner',
                                            })}
                                        </>
                                    }
                                    dataIndex="commission"
                                    key="commission"
                                    width={100}
                                    render={(commission) => {
                                        return (
                                            <p className="table-tbody-text">
                                                {convertPrice(commission)}
                                            </p>
                                        );
                                    }}
                                />
                                <Table.Column
                                    title={
                                        <>
                                            {t('partners.transaction.contact', {
                                                ns: 'partner',
                                            })}
                                        </>
                                    }
                                    dataIndex="contactName"
                                    key="contactName"
                                    width={150}
                                    render={(contactName, record: IPartner.IPartnerTransaction) => {
                                        return (
                                            <Link
                                                to={`/sale/contacts/show/${record.contactId}`}
                                                className="table-tbody-text underline"
                                            >
                                                {contactName}
                                            </Link>
                                        );
                                    }}
                                />
                            </Table>
                        </div>
                        <div className="pagination-container pt-3 pb-4 px-6">
                            <CustomPagination
                                pageSize={pageSize}
                                total={partnerTransactionData?.data?.total}
                                current={current}
                                onChange={(value: number, size: number) => {
                                    setCurrent(value);
                                    setPageSize(size);
                                }}
                                showSizeChanger={false}
                                showLessItems
                            />
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
